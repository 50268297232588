@import url('variables.css');
@import url('Typography.css');

.row--hero {
    height: 75vh;
    background: url('../img/hero-bg.jpg');
    background-position: center;
    background-size: 100% 300%;
    align-items: center;
    position: relative;
    overflow: hidden;
    z-index: 0;

    @media (min-width: 1024px) {
        background-size: cover;
        height: 100vh;
    }


    img[alt="lebron"],
    img[alt="messi"] {
        position: absolute;
        bottom: 0;
        z-index: 2;
    }

    img[alt="lebron"] {
        height: 100%;
        left: 0;
        transform: translateX(-55%);

        @media (min-width: 1024px) {
            transform: translateX(-40%);
        }
    }

    img[alt="messi"] {
        height: 80%;
        right: 0;
        transform: translateX(35%);

        @media (min-width: 1024px) {
            height: 85%;
            transform: translateX(0%);
        }
    }

    @media (min-width: 768px) {
        justify-items: flex-end;

        img[alt="lebron"],
        img[alt="messi"] {
            opacity: 1;
        }
    }

    .hero--text {
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.4);
        z-index: 2;
        color: wheat;

        h2 {
            --_clr-neon: white;

            font-weight: normal;
            letter-spacing: 20px;
        }
    }

    .btn--join {
        color: var(--clr-CTA);
        border-radius: var(--br);
        padding: var(--space-s) var(--space-l);
        text-decoration: none;
        position: absolute;
        bottom: var(--space-m);
        left: 50%;
        z-index: 10;
        transform: translate(-50%, 0);
        font-size: var(--fs-h4);
        border: 1px solid var(--clr-CTA);
        background-color: var(--clr-BG);

        @media (min-width: 1024px) {
            font-weight: 600;
            bottom: var(--space-xl);
        }
    }

    .btn--join:hover {
        color: black;
        background-color: var(--clr-CTA);
        text-decoration: underline;
    }
}