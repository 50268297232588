@import url('./variables.css');

header {
    --_header-h: 50px;

    height: var(--_header-h);
    width: 100%;
    position: fixed;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--clr-BG-T);

    img,
    .header--login {
        margin-inline: var(--space-s);

        @media (min-width: 1024px) {
            margin-inline: var(--space-xl);
        }
    }

    img {
        height: var(--_header-h);
        width: auto;
    }

    nav {
        display: none;
        gap: var(--space-xl);
        color: var(--clr-TXT);
        opacity: 0.5;

        a {
            color: var(--clr-TXT);
            text-decoration: none;
            font-size: 1.2rem;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .hamburger-menu {
        display: grid;
        position: absolute;
        top: 50%;
        right: var(--space-s);
        gap: 5px;
        cursor: pointer;
        transform: translate(-50%, -50%);
        z-index: 100;

        .hamburger-layer {
            height: 2.2px;
            width: 1.2rem;
            border-radius: 5px;
            background-color: white;
            transition: transform 0.3s ease;
        }

        &.open .hamburger-layer:nth-of-type(1) {
            transform: translateY(7px) rotate(45deg);
        }

        &.open .hamburger-layer:nth-of-type(2) {
            opacity: 0;
        }

        &.open .hamburger-layer:nth-of-type(3) {
            transform: translateY(-7px) rotate(-45deg);
        }
    }

    .side-menu {
        position: fixed;
        right: 0;
        top: 0;
        height: 100vh;
        width: 250px;
        background-color: var(--clr-BG);
        box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
        transform: translateX(100%);
        transition: transform 0.3s ease;

        display: flex;
        flex-direction: column;
        padding: var(--space-m);
        padding-top: 5rem;
        gap: var(--space-m);

        nav {
            display: flex;
            flex-direction: column;
            gap: var(--space-m);

            a {
                color: var(--clr-TXT);
                text-decoration: none;
                font-size: 1.2rem;
                text-align: center;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .header--login {
            margin-inline: auto;
            color: lightgray;
            cursor: pointer;
            display: grid;

            form {
                display: grid;
                gap: 0.5rem;
                justify-content: stretch;

            }

        }

        &.open {
            transform: translateX(0);
        }
    }

    @media (min-width: 768px) {
        nav {
            display: flex;
        }

        .header--login {
            display: flex;
            width: 150px;
        }

        .hamburger-menu {
            display: grid;
        }
    }
}