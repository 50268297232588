@import url('./variables.css');

.page--matches {
    width: 100%;
    height: 100svh;
    display: grid;
    place-items: self-end;
    overflow: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;

        &-thumb {
            background-color: #555;
            border-radius: 10px;

            &:hover {
                background-color: #777;
            }
        }

        &-track {
            background-color: transparent;
        }
    }

    /* For Firefox */
    scrollbar-width: thin;
    scrollbar-color: rgb(85, 85, 85) transparent;

    .matches--title {
        position: absolute;
        top: 50px;
        left: 50%;
        width: max-content;
        transform: translateX(-50%);
        margin: 0;

        @media (min-width: 768px) {
            margin: 1.5rem 0;
        }
    }

}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;

    .match--modal {
        display: grid;
        justify-content: center;
        align-items: start;
        width: 800px;
        height: auto;
        background: var(--clr-CTA-20);
        overflow: hidden;
        padding: var(--space-l);
        position: relative;

        .match--date,
        .match--player {
            display: flex;
            align-items: center;
            gap: 1rem;

            h4 {
                font-size: 1.5rem;
                color: var(--clr-CTA);
                margin: 0;
            }
        }

        .success {
            color: greenyellow;

        }

        .btn--logOut {
            border: 1px solid red;
            background-color: transparent;
            color: red;
            border-radius: var(--br);
            width: max-content;
            padding: .25rem 1rem;
            margin-bottom: 1rem;
            margin-left: auto;
            opacity: 0.75;
        }

        .match--date {}

        .gamesList {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 1rem;
            margin-bottom: 1.5rem;

            label {
                height: 100%;
                place-items: center;
                display: grid;
                align-content: center;
                justify-content: center;
            }

            input[type="checkbox"] {
                display: none;
                position: absolute;
                /* Skryje skutečný checkbox */
            }

            .game-logo {
                display: inline-flex;
                max-width: 90%;
                height: auto;
                object-fit: contain;
                filter: grayscale(50);
                cursor: pointer;
                transition: filter 0.3s ease;

                &[alt="UFC"] {
                    filter: grayscale(50) brightness(2);
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    opacity: 0.5;
                    /* Poloprůhledné pro nezaškrtnutý stav */
                    transition: opacity 0.3s ease;
                }
            }

            .game-logo.checked {
                filter: grayscale(0);

                img {
                    opacity: 1;
                }
            }

        }
    }
}

.modal-close {
    position: absolute;
    top: var(--space-m);
    right: var(--space-m);
    background-color: red;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 900;
    padding: 5px 10px;
    display: grid;
    place-items: center;
    z-index: 999;
    cursor: pointer;
}