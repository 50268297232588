.row--playersList {
    width: clamp(300px, 100%, 700px);

    h3 {
        text-align: left;
        font-size: var(--fs-h3);
        color: var(--clr-TXT);
    }

    table {
        width: 100%;
        text-align: left;
        border-radius: var(--br);
        border-collapse: collapse;
    }

    thead td {
        font-size: var(--fs-p);
        color: var(--clr-CTA);
        border-bottom: 1px solid var(--clr-CTA);
        background-color: var(--clr-BG);
    }

    tr {
        border-bottom: 1px solid var(--clr-TXT);
    }

    tr:nth-of-type(2n-1) {
        background-color: #5f5f5f5f;
    }

    tr:nth-of-type(2n) {
        background-color: #3a3a3a5f;
    }

    td,
    img {
        vertical-align: middle;
        padding: var(--space-m);
        /* Zarovná text a obrázky na střed */
    }

    td {
        font-size: var(--fs-p);
        font-weight: 600;
    }

    .player-gamesList {
        display: grid;
        min-width: 220px;
        grid-template-columns: repeat(auto-fit, 110px);
        place-items: center;
    }

    img {
        height: 30px;
        width: auto;
    }
}