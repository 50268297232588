@import url('./variables.css');

.userWrapper {

    .userName {
        cursor: pointer;
        font-weight: 600;
        font-size: 1.2rem;
        text-align: center;
    }

    p {
        text-align: left;
        color: var(--clr-CTA);
        font-size: 1rem;
        font-weight: 600;
    }

    .user--menu {
        display: none;
        background-color: var(--clr-BG);
        padding: var(--space-m);
        color: var(--clr-NW);
        border: 1px solid var(--clr-CTA);

        @media (min-width: 768px) {
            margin-inline: -1.5rem;
        }
    }

    .usermenu--show {
        display: block;
    }

    .edit-username,
    .edit-color {
        display: grid;
        grid-template-columns: 80px 1fr;
        justify-items: start;
        gap: var(--space-m);
        margin: var(--space-s) 0;

        input[type="text"] {
            width: 100%;
        }

        input[type="color"] {
            width: 100%;
            background: transparent;
        }
    }

    .remove-user {
        color: var(--clr-PR);
        margin-top: var(--space-m);
        text-decoration: underline;
        cursor: pointer;
    }

    .edit-games {
        display: grid;
        min-width: 130px;
        justify-content: center;
        justify-items: center;
        row-gap: 0.5rem;
        margin-bottom: 1rem;
        grid-template-columns: repeat(auto-fit, 65px);

        input[type="checkbox"] {
            display: none;
        }

        .game-item {
            height: 25px;
            width: auto;

            .game-logo {
                object-fit: contain;
                filter: grayscale(50);

                img {
                    height: 25px;
                    width: auto;
                }

                &.checked {
                    filter: grayscale(0);
                }
            }
        }
    }
}