@import url('./variables.css');
@import url('./Typography.css');

.calendarWrapper {
    display: flex;
    justify-content: flex-start;

    .calendar--day {
        width: 350px;
        background-color: rgba(119, 119, 119, 0.25);
        /*scroll-snap-align: start;*/
        display: grid;
        text-align: center;
        justify-items: stretch;
        margin-inline: 0.25rem;
    }

    .day-date {
        display: grid;
        place-items: center;
        height: 80px;
        background-color: var(--clr-CTA-20);
    }

    .day-hour {
        height: 50px;
        width: 100%;
        display: grid;
        grid-template-columns: max-content 1fr;
        place-items: center start;
        background: transparent;
        border: none;

        &:hover {
            border: 1px solid gold;
        }

        &:nth-of-type(2n) {
            background-color: rgba(119, 119, 119, 0.1);
        }

        span {
            padding: 0 1rem;
            color: whitesmoke;
            font-family: var(--fs-text);
            font-size: var(--fs-p);

            .hour {
                opacity: 0.5;
            }

            &.match--details {
                font-weight: 700;
                display: flex;
                align-items: center;
                padding: 0;
                justify-content: flex-start;

                .match--game {
                    max-width: 50px;
                    max-height: 30px;
                }

                .player--1 {
                    color: red;
                }

                .player--2 {
                    color: blue;
                }
            }
        }
    }
}

.match--details {}