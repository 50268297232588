@import url('./variables.css');

:root {
    --fs-heading: "Titillium Web", sans-serif;
    --fs-text: "Nunito", sans-serif;
}

h1 {
    font-family: var(--fs-heading);
    font-size: var(--fs-h1);
    font-weight: 500;
    margin-bottom: var(--space-m);
    text-transform: uppercase;
}

h2 {
    font-family: var(--fs-heading);
    font-size: var(--fs-h2);
    font-weight: 600;
}

h3 {
    font-family: var(--fs-heading);
    font-size: var(--fs-h3);
    font-weight: 700;
}

h4 {
    color: var(--clr-NW);
    font-family: var(--fs-p);
    font-size: var(--fs-h4);
    line-height: 1.5;
}

p {
    font-family: var(--fs-p);
    font-size: var(--fs-p);
    opacity: 0.6;
    font-weight: 300;
    line-height: 1.5;
    margin-top: var(--space-s);

    @media (min-width: 768px) {
        font-size: 1.125rem;
    }
}

.neon-text {
    --_clr-neon: red;
    color: var(--_clr-neon);
    /* Barva textu */
    text-shadow:
        0 0 5px var(--_clr-neon),
        /* Jemný zářící efekt */
        0 0 10px var(--_clr-neon),
        0 0 15px var(--_clr-neon),
        0 0 25px var(--_clr-neon);
    /* Maximální záře */
    letter-spacing: 2px;
    /* Trochu větší mezery mezi písmeny */
    animation: neon-flicker 2s infinite;
    /* Efekt blikání */
}

@keyframes colorSwitch {
    0% {
        --_clr-neon: blue;
    }

    100% {
        --_clr-neon: red;
    }
}


/* Efekt blikání */
@keyframes neon-flicker {

    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {
        text-shadow:
            0 0 5px var(--_clr-neon),
            0 0 10px var(--_clr-neon),
            0 0 15px var(--_clr-neon),
            0 0 25px var(--_clr-neon);

    }

    20%,
    24%,
    55% {
        text-shadow:
            0 0 2px var(--_clr-neon),
            0 0 4px var(--_clr-neon),
            0 0 8px var(--_clr-neon);
    }
}