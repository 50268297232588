table.result--table {
    margin-inline: auto;
    transform: translateY(6rem);
    border-collapse: collapse;

    th,
    td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
    }

    th {
        background-color: rgba(242, 242, 242, 0.226);
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        word-wrap: break-word;
        max-height: 100px;
    }
}

/* GameFilter.css */
.results--filter {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    transform: translateY(4rem);
}

.game-option {
    position: relative;
    cursor: pointer;
}

.game-option input[type="radio"] {
    display: none;
}

.game-image {
    width: 100px;
    height: auto;
    transition: filter 0.3s ease;
    filter: grayscale(100%);
    border: 2px solid transparent;
    border-radius: 10px;
}

.game-option.selected .game-image {
    filter: none;
    border-color: #007bff;
}