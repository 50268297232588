@import url('./variables.css');

.row--info {
    width: clamp(300px, 100%, 700px);
    text-align: left;

    h3,
    li {
        color: var(--clr-TXT);
    }

    h3 {
        cursor: pointer;
        border-bottom: 1px solid var(--clr-TXT);
        position: relative;
    }

    h3::after {
        content: '';
        height: 30px;
        width: 30px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0%, -50%);
        background: url('../img/arrow.svg');
        background-size: contain;
        color: white;
    }

    .overflow {
        --_transition-t: 350ms;

        height: 0;
        overflow: hidden;
        transition: height 0s ease var(--_transition-t);

        .info-list {
            list-style: none;
            /* Odstranění výchozích odrážek */
            counter-reset: list-counter;
            transform: translateY(-100%);
            transition: transform var(--_transition-t) ease-in-out;
        }

        li {
            counter-increment: list-counter;
            font-size: var(--fs-h4);
            margin-bottom: var(--space-l);
            /* Zvýšení počítadla na každém <li> */
        }

        li::marker {
            content: counter(list-counter) ". ";
            /* Vložení číslování jako marker */
            font-weight: bold;
            /* Například modré číslování */
        }
    }
}

.info--show {

    .overflow {
        height: 100%;
    }

    .info-list {
        transform: translateY(0) !important;
    }

    h3::after {
        transform: translate(0%, -50%) rotate(180deg);
    }
}