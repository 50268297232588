@import url('./variables.css');

.games--list {
    max-width: 100%;
    padding: calc((100% - 1600px) / 2);
    background: var(--clr-BG);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: var(--space-l);
    padding: var(--space-l);

    .game {
        height: 60px;
        object-fit: contain;
        color: white;
        display: grid;
        align-content: center;
        filter: grayscale(50);
    }

    .game:hover {
        filter: grayscale(0);
    }

    .game.maybe {
        opacity: 0.6;
    }
}

.page--games {
    display: grid;
    justify-content: center;
    padding: var(--space-l);
    padding-top: var(--space-xl);

    @media (min-width: 768px) {
        gap: var(--space-xl);
    }

    .gameWrapper {
        max-width: 1440px;
        margin-inline: auto;
        display: grid;


        @media (min-width: 768px) {
            gap: var(--space-xl);
            grid-template-columns: 1fr 1fr;

            &:nth-last-of-type(2n) {
                .game--text {
                    order: 1;
                }
            }
        }

        .game--text {
            max-width: 630px;

            h3 {
                margin-bottom: 0;
            }
        }
    }
}