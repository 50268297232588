:root {
    /* Font variables */
    /*----------------*/
    /* Font family nadpisů*/
    --ff-headers: "Montserrat", serif;
    /* Font family obyč textů*/
    --ff-paragraphs: "Inter", sans-serif;


    /* Color variables */
    /*-----------------*/
    /* Pozadí */
    --clr-BG: #0a090a;
    /* Transparentní pozadí */
    --clr-BG-T: rgba(74, 190, 142, 0.25);
    /* Sekundární Pozadí */
    --clr-SBG: #352e35;
    /* primární modrá */
    --clr-PB: #3c02c4;
    /* primární červená */
    --clr-PR: #da2222;
    /* sekundární fialová */
    --clr-CTA: rgb(74, 190, 142);
    /* sekundární fialová */
    --clr-CTA-20: rgba(74, 190, 142, 0.2);
    /* sekundární modrá */
    --clr-TXT: rgb(255, 243, 221);
    --clr-TXT-50: rgba(245, 222, 179, 0.5);
    /* neutrální černá */
    --clr-NB: #2F2F2F;
    /* neutrální šedá */
    --clr-NG: #E5E5F7;
    /* neutrální bílá */
    --clr-NW: #FFF;
    /* kontrastní modrá */
    --clr-CB: #363D63;
    /* Lineární gradient primární */
    --grad: linear-gradient(90deg, var(--clr-PV) 0%, var(--clr-PB) 100%);


    /* Space variables */
    /*-----------------*/
    /* Maximální šířka obsahu stránky */
    --content-w: 1440px;
    /* Malá mezera */
    --space-s: 0.5rem;
    /* Střední mezera */
    --space-m: 1rem;
    /* Velká mezera */
    --space-l: 2.5rem;
    /* Extra velká mezera */
    --space-xl: 5rem;
    /* 2Extra velká mezera */
    --space-xxl: 10rem;


    /* Maximální šířka stránky*/
    --page-w: 1440px;

    /*Border radius*/
    --br: 11px;

    /*Header height*/
    --header-h: 70px;

    /*Footer height*/
    --footer-h: 140px;


    /* Velikosti písma */
    --fs-h1: 5rem;
    --fs-h2: 4rem;
    --fs-h3: 1.5rem;
    --fs-h4: 1rem;
    --fs-p: 0.875rem;

    @media (min-width: 768px) {
        --fs-h1: 16rem;
        --fs-h2: 6rem;
        --fs-h3: 3rem;
        --fs-h4: 1.5rem;
        --fs-p: 1rem;
    }
}