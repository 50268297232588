body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  counter-reset: li;
  background-image: url(../img/page-bg.jpg);
  color: whitesmoke;
}


@import url('./variables.css');
@import url('./Hero.css');

html {
  scroll-behavior: smooth;
}


.Home {
  text-align: center;
  background-size: auto;
  overflow: hidden;
}

section:not(.row--hero) {
  margin: var(--space-xl) auto;
  padding-inline: 1rem;
  box-sizing: border-box;
}