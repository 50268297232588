@import url('./variables.css');

.row--form--join {
    width: clamp(300px, 100%, 700px);
    display: grid;
    background: #1a1a1a;
    border-radius: var(--br);
    justify-items: center;
    border: 5px solid;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    padding: var(--space-m);

    @media (min-width: 1024px) {
        padding: var(--space-xl);
    }

    h3 {
        margin-top: 0;
        color: var(--clr-TXT);
    }

    form {
        display: grid;
        grid-template-rows: 60px;
        gap: var(--space-l);
        padding-inline: 1rem;
    }

    div:has(>input) {
        display: grid;
        justify-content: stretch;
        width: 100%;
    }

    label {
        text-align: left;
        color: var(--clr-NW);
        display: grid;
        padding-bottom: var(--space-s);
    }

    .colorsList {
        display: flex;
        flex-wrap: wrap;

        .color-box {
            width: 40px;
            height: 40px;
            display: inline-block;
            box-sizing: border-box;
            margin: 5px;
            border-radius: 50%;
            cursor: pointer;
            border: 2px solid transparent;
            transition: border 0.3s ease;
        }

        .color-box.selected {
            border: 4px solid yellow;
        }

    }

    .gamesList {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2rem;

        label {
            height: 100%;
            place-items: center;
        }

        input[type="checkbox"] {
            display: none;
            position: absolute;
            /* Skryje skutečný checkbox */
        }

        .game-logo {
            display: inline-flex;
            height: 60%;
            max-width: 60%;
            object-fit: contain;
            filter: grayscale(50);
            display: grid;
            justify-content: center;
            align-content: center;
            /* Šedivé pozadí pro nezaškrtnutý stav */
            cursor: pointer;
            transition: filter 0.3s ease;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                opacity: 0.5;
                /* Poloprůhledné pro nezaškrtnutý stav */
                transition: opacity 0.3s ease;
            }
        }

        .game-logo.checked {
            filter: grayscale(0);

            img {
                opacity: 1;
            }
        }


    }

    input[type="text"] {
        background-color: var(--clr-NW);
        border-radius: var(--br);
        padding: var(--space-s);

        font-size: var(--fs-p);
        height: 100%;
    }

    .btn--register {
        background-color: black;
        color: var(--clr-CTA);
        border: 1px solid var(--clr-CTA);
        border-radius: var(--br);
        padding: var(--space-s) var(--space-l);
        text-decoration: none;
        font-size: var(--fs-h4);
        font-weight: 600;
        cursor: pointer;
    }

    .btn--register:hover {
        color: black;
        background-color: var(--clr-CTA);

    }

    .random-nick-btn {
        background-color: black;
        color: white;
    }

    .error {
        color: rgb(255, 0, 0);
        font-size: var(--fs-p);
        text-align: left;
        margin-top: 10px;
    }
}

.neon-card {
    position: relative;
}

/* Neonový efekt na okraji (border) */
.neon-card::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-radius: 15px;
    background: none;
    border: 1px solid var(--_clr-neon);
    /* Barva neonového okraje */
    box-shadow: 0 0 5px var(--_clr-neon),
        0 0 10px var(--_clr-neon),
        0 0 20px var(--_clr-neon),
        0 0 30px var(--_clr-neon),
        inset 0 0 5px var(--_clr-neon),
        /* Vnitřní neonová záře */
        inset 0 0 10px var(--_clr-neon),
        /* Vnitřní neonová záře */
        inset 0 0 20px var(--_clr-neon),
        /* Vnitřní neonová záře */
        inset 0 0 30px var(--_clr-neon);
    /* Vnitřní neonová záře */
    /* Neonová záře okraje */
    /* Zajistí, že okraj bude za obsahem karty */
    animation: neon-border-flicker 2s infinite;
    pointer-events: none;
}

/* Efekt blikání pro border */
@keyframes neon-border-flicker {

    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {
        box-shadow:
            0 0 5px var(--_clr-neon),
            0 0 10px var(--_clr-neon),
            0 0 20px var(--_clr-neon),
            0 0 30px var(--_clr-neon),
            inset 0 0 5px var(--_clr-neon),
            inset 0 0 10px var(--_clr-neon),
            inset 0 0 20px var(--_clr-neon),
            inset 0 0 30px var(--_clr-neon);
    }

    20%,
    24%,
    55% {
        box-shadow:
            0 0 2px var(--_clr-neon),
            0 0 4px var(--_clr-neon),
            0 0 8px var(--_clr-neon),
            inset 0 0 2px var(--_clr-neon),
            inset 0 0 4px var(--_clr-neon),
            inset 0 0 8px var(--_clr-neon);
    }
}